<template>
  <!-- <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a
        @mouseover="fakeTitle"
        @mouseleave="realTitle"
        class="navbar-brand"
        id="title"
        href="/home"
      >
        {{ title }}</a
      >
      <p id="slogan">{{ $t("navbar.slogan") }}</p>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item" v-if="this.userStore.user.role == 'admin' || this.userStore.user.role == 'superadmin'">
            <a
              class="nav-link"
              id="submitButton"
              aria-current="page"
              href="/admin"
              >admin dashboard</a
            >
          </li>
          <li class="nav-item" v-if="this.userStore.user.username">
            <a
              class="nav-link"
              id="submitButton"
              aria-current="page"
              href="/submitplace"
              >submit a new place</a
            >
          </li>
          <li class="nav-item" v-if="this.userStore.user.username">
            <a class="nav-link" aria-current="page" href="#">{{
              this.userStore.user.username
            }}</a>
          </li>
          <li class="nav-item" v-if="!this.userStore.user.username">
            <a class="nav-link" aria-current="page" href="/login">login</a>
          </li>
          <li class="nav-item" v-if="!this.userStore.user.username">
            <a class="nav-link" href="/signup">sign up</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              v-if="this.userStore.user.username"
              v-on:click="logout"
              >logout</a
            >
          </li>
          <li class="nav-item">
            <select
              class="nav-link form-select form-select-sm"
              aria-label=".form-select-sm example"
              v-model="$root.$i18n.locale"
            >
              <option
                v-for="locale in $root.$i18n.availableLocales"
                :key="`locale-${locale}`"
                :value="locale"
              >
                {{ locale }}
              </option>
            </select>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->

  <header>
    <nav class="navbar bg-body-tertiary">
      <div class="container-fluid">
        <!-- <div class="titleContainer"> -->
        <a
          @mouseover="fakeTitle"
          @mouseleave="realTitle"
          class="navbar-brand"
          id="title"
          href="/home"
        >
          {{ title }}</a
        >
        <!-- <p class="navbar-brand" id="slogan">{{ $t("navbar.slogan") }}</p> -->
        <!-- </div> -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div class="offcanvas-header">
            <h5
              class="offcanvas-title"
              id="offcanvasNavbarLabel"
              v-if="!this.userStore.user.username"
            >
              wherever-leiria
            </h5>
            <h5
              class="offcanvas-title"
              id="offcanvasNavbarLabel"
              v-if="this.userStore.user.username"
            >
              {{ this.userStore.user.username }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li class="nav-item" v-if="this.userStore.user.username">
                <a class="nav-link navButton" aria-current="page" href="/home"
                  >Home</a
                >
              </li>
              <li class="nav-item" v-if="this.userStore.user.username">
                <a class="nav-link navButton" aria-current="page" href="/places"
                  >Todos os sítios</a
                >
              </li>
              <li class="nav-item dropdown" v-if="this.userStore.user.username">
                <a
                  class="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Utilizador
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="/profile">Perfil</a></li>
                  <!-- <li><a class="dropdown-item" href="#">Another action</a></li> -->
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      id="redButton"
                      v-if="this.userStore.user.username"
                      v-on:click="logout"
                      >Sair</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown" v-if="this.userStore.user.role == 'admin' ||
                      this.userStore.user.role == 'superadmin'">
                <a
                  class="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Admin
                </a>

                <ul class="dropdown-menu">
                  <li
                    class="nav-item"
                    v-if="
                      this.userStore.user.role == 'admin' ||
                      this.userStore.user.role == 'superadmin'
                    "
                  >
                    <a
                      class="dropdown-item"
                      aria-current="page"
                      href="/admin"
                      >Submitted places</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="this.userStore.user.role == 'superadmin'"
                  >
                    <a
                      class="dropdown-item"
                      aria-current="page"
                      href="/admin/stats"
                      >User Stats</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="this.userStore.user.role == 'admin' ||
                      this.userStore.user.role == 'superadmin'"
                  >
                    <a
                      class="dropdown-item"
                      aria-current="page"
                      href="/admin/reports"
                      >Reports</a
                    >
                  </li>
                </ul>
              </li>

              <li>
                <hr class="divider" v-if="this.userStore.user.username" />
              </li>
              <li class="nav-item" v-if="this.userStore.user.username">
                <a
                  class="nav-link navButton"
                  id="submitButton"
                  aria-current="page"
                  href="/submitplace"
                  >Submeter um novo sítio</a
                >
              </li>
              <li class="nav-item" v-if="this.userStore.user.username">
                <a
                  class="nav-link navButton"
                  id="redButton"
                  aria-current="page"
                  href="/createreport"
                  >Reportar um problema</a
                >
              </li>
              <!-- <li class="nav-item" v-if="this.userStore.user.username">
            <a class="nav-link" aria-current="page" href="#">{{
              this.userStore.user.username
            }}</a>
          </li> -->
              <li class="nav-item" v-if="!this.userStore.user.username">
                <a class="nav-link" aria-current="page" href="/login">Entrar</a>
              </li>
              <li class="nav-item" v-if="!this.userStore.user.username">
                <a class="nav-link" href="/signup">Criar conta</a>
              </li>
              <div class="warningDiv">
                <li>
                  <hr class="divider" v-if="this.userStore.user.username" />
                </li>
                <li>
                  <p id="warning" v-if="this.userStore.user.username">
                    A plataforma ainda se encontra em desenvolvimento por isso
                    poderão existir alguns erros.<br />
                    Caso encontres algum problema ou tens sugestões para
                    melhorar a plataforma clica <a id="warning" href="/contact">aqui</a> para enviar o teu feedback.
                  </p>
                </li>
                <li>
                  <hr class="divider" v-if="!this.userStore.user.username" />
                </li>
                <li>
                  <p id="warning" v-if="!this.userStore.user.username">
                    Cria uma conta gratuita para poderes votar e adicionar sítios novos!<br />
                    Caso te tenhas esquecido da palavra-passe ou tenhas algum problema
                    envia um email para <a id="warning" href="mailto:support@wherever.pt">support@wherever.pt</a>.
                  </p>
                </li>
              </div>

              <!-- <li class="nav-item">
            <select
              class="nav-link form-select form-select-sm"
              aria-label=".form-select-sm example"
              v-model="$root.$i18n.locale"
            >
              <option
                v-for="locale in $root.$i18n.availableLocales"
                :key="`locale-${locale}`"
                :value="locale"
              >
                {{ locale }}
              </option>
            </select>
          </li> -->
            </ul>
            <!-- <form class="d-flex mt-3" role="search">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn btn-outline-success" type="submit">
                Search
              </button>
            </form> -->
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { useUserStore } from "../store/user";
import axios from "axios";

export default {
  name: "NavBar",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      title: "wherever-leiria",
      email: "",
      password: "",
    };
  },
  mounted() {},
  methods: {
    fakeTitle() {
      this.title = "leiria-existe";
      //console.log(this.userStore.user);
      //setTimeout(() => this.title ="where-leiria", 200)
    },
    realTitle() {
      this.title = "wherever-leiria";
      //console.log(this.userStore.isLoggedIn);
      //console.log(this.userStore.user.username);
      //setTimeout(() => this.title ="where-leiria", 200)
    },
    logout() {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
          withCredentials: true,
        })
        .then(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.$router.push({ name: "landingpage" });
        });
      //localStorage.clear();
      //sessionStorage.clear();
      //location.reload(); uncomment this after closed beta and remove next line
      //this.$router.push({ name: "landingpage" });
    },
  },
  // props: {
  // 	msg: String,
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  border-bottom: 2px solid #6246ea;
  //border: #000000;
  //background-color: #ffffff;
}

#title {
  margin-left: 5%;
  /* background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  //font-family: 'Roboto Mono', monospace;
  font-weight: 900;
  color: #6246ea;
  font-size: 25px;
  letter-spacing: 3px;
}

.titleContainer {
  //display: flex;

  //flex-direction: row;
  margin-left: -10%;
  //align-content: center;
  //justify-content: space-between;
  width: 70%;
  //line-height: 10px;
}

.warningDiv {
  position: absolute;
  bottom: 0;
  margin: 0 4% 5% 0;
}

#warning {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0;
}

a {
  color: #2b2c34;
  font-size: 25px;
}

a.nav-link,
a.dropdown-item {
  //font-size: 120%;
  font-size: 100%;
  font-weight: 700;
  //color: #2B2C34;
  //color: #a7a9be;
  //margin-left: 2%;
  //border: 2px solid #6246ea;
}

.offcanvas-title {
  font-weight: 700;
}

.nav-link {
  //background-color: #6246ea;
  border-radius: 10%;
}

#redButton {
  color: #e45858;
}

.navButton {
  //margin-right: 4%;
  //width: 100% !important;
}

#submitButton {
  color: #6246ea;
}

#slogan {
  font-weight: 700;
  //color: ##2b2c34;
  //margin-top: 1%;
  //margin-lef: 2%;
  //color: #a7a9be;
  //width: 100% !important;
  margin: 0px;
  //font-size: 120%;
  padding: 0;
  vertical-align: middle;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  #title {
    margin-left: 5%;
    /* background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
    //font-family: 'Roboto Mono', monospace;
    font-weight: 900;
    color: #6246ea;
    font-size: 50px;
    letter-spacing: 3px;
  }
}
</style>
