import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Places from '../views/Places.vue'

const routes = [
  {
    path: '/',
    name: 'landingpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/LandingPage.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }
      //return { name: 'places'}

    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/places',
    name: 'places',
    component: Places,
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }

    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'true') {
        return { name: 'home' }
      }

    },
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'true') {
        return { name: 'places' }
      }

    },
  },
  {
    path: '/submitplace',
    name: 'createplace',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreatePlace.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }

    },
  },
  {
    path: '/contact',
    name: 'createreport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateReport.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }

    },
  },
  {
    path: '/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }

      if (JSON.parse(sessionStorage.getItem('user')).role != "admin" && JSON.parse(sessionStorage.getItem('user')).role != "superadmin") {
        return { name: 'home' }
      }

    },

  },

  {
    path: '/admin/stats',
    name: 'stats',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Stats.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }

      if (JSON.parse(sessionStorage.getItem('user')).role != "superadmin") {
        return { name: 'home' }
      }

    },

  },

  {
    path: '/admin/reports',
    name: 'reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Reports.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }

      if (JSON.parse(sessionStorage.getItem('user')).role != "admin" && JSON.parse(sessionStorage.getItem('user')).role != "superadmin") {
        return { name: 'home' }
      }

    },

  },

  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    beforeEnter: () => {
      // reject the navigation
      if (sessionStorage.getItem('isAuthenticated') == 'false') {
        return { name: 'login' }
      }
    },
  },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
