<template>
  <div class="homeContainer">
    <div class="featuredPlacesContainer">
      <h2 class="containerTitle">Mais votados</h2>

      <div class="spinner-border" role="status" v-if="!topPlaces">
        <span class="visually-hidden">Loading...</span>
      </div>

      <div class="placesContainer">
        <div
          class="accordion accordion-flush"
          id="accordion"
          v-for="(place, index) in topPlaces"
          v-bind:key="place.id"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'flush-heading' + index">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#flush-collapse' + index"
                aria-expanded="false"
                :aria-controls="'flush-collapse' + index"
              >
                <div class="container-fluid">
                  <p class="accordionItem" id="name">{{ place.name }}</p>

                  <p
                    class="accordionItem"
                    id="category"
                    v-if="place.category == 'Cafe'"
                  >
                    Café
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Nightlife'"
                  >
                    Nightlife
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Activity'"
                  >
                    Atividade
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Sports'"
                  >
                    Desporto
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Restaurant'"
                  >
                    Restaurante
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Bar'"
                  >
                    Bar
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Snacks'"
                  >
                    Snacks
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Other'"
                  >
                    Outro
                  </p>
                  <p class="accordionItem" id="category" v-else>
                    Sem categoria
                  </p>

                  <p class="show-on-desktop accordionItem" id="zone">
                    {{ place.zone }}
                  </p>
                  <p
                    class="show-on-desktop accordionItem"
                    id="votesVoted"
                    v-if="
                      upvotedPlaces != null && upvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }} gostos
                  </p>
                  <p
                    class="show-on-desktop accordionItem"
                    id="votesDownvoted"
                    v-else-if="
                      downvotedPlaces != null &&
                      downvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }} gostos
                  </p>
                  <p class="show-on-desktop accordionItem" id="votes" v-else>
                    {{ place.upvotes }} gostos
                  </p>
                </div>
              </button>
            </h2>
            <div
              :id="'flush-collapse' + index"
              class="accordion-collapse collapse"
              :aria-labelledby="'flush-heading' + index"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                {{ place.description }}
                <p class="show-on-mobile accordionItem" id="zone">
                  {{ place.zone }}
                </p>
                <div class="buttonsContainer">
                  <button
                    class="btn voteButton"
                    id="upvoteButton"
                    type="button"
                    @click.prevent="upvote(place)"
                    v-if="
                      upvotedPlaces == null ||
                      !upvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-up.svg"
                      alt="Thumbs up icon"
                    />
                  </button>
                  <button
                    class="btn voteButton"
                    id="upvoteButton"
                    type="button"
                    @click.prevent="upvote(place)"
                    v-if="
                      upvotedPlaces != null && upvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-up-clicked.svg"
                      alt="Thumbs up icon clicked"
                    />
                  </button>
                  <p
                    class="show-on-mobile accordionItem"
                    id="votesVoted"
                    v-if="
                      upvotedPlaces != null && upvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }}
                  </p>
                  <p
                    class="show-on-mobile accordionItem"
                    id="votesDownvoted"
                    v-else-if="
                      downvotedPlaces != null &&
                      downvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }}
                  </p>
                  <p class="show-on-mobile accordionItem" id="votes" v-else>
                    {{ place.upvotes }}
                  </p>
                  <button
                    class="btn voteButton"
                    id="downvoteButton"
                    type="button"
                    @click.prevent="downvote(place)"
                    v-if="
                      downvotedPlaces == null ||
                      !downvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-down.svg"
                      alt="Thumbs down icon"
                    />
                  </button>
                  <button
                    class="btn voteButton"
                    id="downvoteButton"
                    type="button"
                    @click.prevent="downvote(place)"
                    v-if="
                      downvotedPlaces != null &&
                      downvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-down-clicked.svg"
                      alt="Thumbs down icon clicked"
                    />
                  </button>
                  <p id="warning">Os votos negativos não são apresentados.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="seeAllButton" href="/places">Ver todos os sítios</a>
    </div>
    <div class="newPlacesContainer">
      <h2 class="containerTitle">Adicionados recentemente</h2>
      <div class="placesContainer">
        <div class="spinner-border" role="status" v-if="!recentPlaces">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div
          class="accordion accordion-flush"
          id="accordion"
          v-for="(place, index) in recentPlaces"
          v-bind:key="place.id"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'flush-heading' + index + 100">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#flush-collapse' + index + 100"
                aria-expanded="false"
                :aria-controls="'flush-collapse' + index + 100"
              >
                <div class="container-fluid">
                  <p class="accordionItem" id="name">{{ place.name }}</p>

                  <p
                    class="accordionItem"
                    id="category"
                    v-if="place.category == 'Cafe'"
                  >
                    Café
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Nightlife'"
                  >
                    Nightlife
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Activity'"
                  >
                    Atividade
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Sports'"
                  >
                    Desporto
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Restaurant'"
                  >
                    Restaurante
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Bar'"
                  >
                    Bar
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Snacks'"
                  >
                    Snacks
                  </p>
                  <p
                    class="accordionItem"
                    id="category"
                    v-else-if="place.category == 'Other'"
                  >
                    Outro
                  </p>
                  <p class="accordionItem" id="category" v-else>
                    Sem categoria
                  </p>

                  <p class="show-on-desktop accordionItem" id="zone">
                    {{ place.zone }}
                  </p>
                  <p
                    class="show-on-desktop accordionItem"
                    id="votesVoted"
                    v-if="
                      upvotedPlaces != null && upvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }} gostos
                  </p>
                  <p
                    class="show-on-desktop accordionItem"
                    id="votesDownvoted"
                    v-else-if="
                      downvotedPlaces != null &&
                      downvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }} gostos
                  </p>
                  <p class="show-on-desktop accordionItem" id="votes" v-else>
                    {{ place.upvotes }} gostos
                  </p>
                </div>
              </button>
            </h2>
            <div
              :id="'flush-collapse' + index + 100"
              class="accordion-collapse collapse"
              :aria-labelledby="'flush-heading' + index + 100"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                {{ place.description }}
                <p class="show-on-mobile accordionItem" id="zone">
                  {{ place.zone }}
                </p>
                <div class="buttonsContainer">
                  <button
                    class="btn voteButton"
                    id="upvoteButton"
                    type="button"
                    @click.prevent="upvote(place)"
                    v-if="
                      upvotedPlaces == null ||
                      !upvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-up.svg"
                      alt="Thumbs up icon"
                    />
                  </button>
                  <button
                    class="btn voteButton"
                    id="upvoteButton"
                    type="button"
                    @click.prevent="upvote(place)"
                    v-if="
                      upvotedPlaces != null && upvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-up-clicked.svg"
                      alt="Thumbs up icon clicked"
                    />
                  </button>
                  <p
                    class="show-on-mobile accordionItem"
                    id="votesVoted"
                    v-if="
                      upvotedPlaces != null && upvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }}
                  </p>
                  <p
                    class="show-on-mobile accordionItem"
                    id="votesDownvoted"
                    v-else-if="
                      downvotedPlaces != null &&
                      downvotedPlaces.includes(place._id)
                    "
                  >
                    {{ place.upvotes }}
                  </p>
                  <p class="show-on-mobile accordionItem" id="votes" v-else>
                    {{ place.upvotes }}
                  </p>
                  <button
                    class="btn voteButton"
                    id="downvoteButton"
                    type="button"
                    @click.prevent="downvote(place)"
                    v-if="
                      downvotedPlaces == null ||
                      !downvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-down.svg"
                      alt="Thumbs down icon"
                    />
                  </button>
                  <button
                    class="btn voteButton"
                    id="downvoteButton"
                    type="button"
                    @click.prevent="downvote(place)"
                    v-if="
                      downvotedPlaces != null &&
                      downvotedPlaces.includes(place._id)
                    "
                  >
                    <img
                      src="../../public/thumbs-down-clicked.svg"
                      alt="Thumbs down icon clicked"
                    />
                  </button>
                  <p id="warning">Os votos negativos não são apresentados.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="seeAllButton" href="/places">Ver todos os sítios</a>
    </div>
    <!-- <div class="nextEventsContainer">
      <h2 class="containerTitle">Eventos</h2>
      <p>Brevemente!</p>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { useUserStore } from "../store/user";

export default {
  name: "FeaturedPlaces",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      topPlaces: null,
      recentPlaces: null,
      wasUpvoted: false,
      wasDownvoted: false,
      categoryFilter: "",
      upvotedPlaces: null,
      downvotedPlaces: null,
      textInput: "",
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/topplaces", {
        withCredentials: true,
      })
      .then(
        (response) => (
          (this.topPlaces = response.data.data),
          (this.nonFilteredPlaces = response.data.data)
        )
      )
      .catch(() => {
        axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
          withCredentials: true,
        });
        localStorage.clear();
        sessionStorage.clear();
        //location.reload(); uncomment this after closed beta and remove next line
        this.$router.push({ name: "landingpage" });
      });

    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/recentplaces", {
        withCredentials: true,
      })
      .then(
        (response) => (
          (this.recentPlaces = response.data.data),
          (this.nonFilteredPlaces = response.data.data)
        )
      );

    if (this.userStore.user.username) {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/user/upvotedplaces", {
          withCredentials: true,
        })
        .then((response) => (this.upvotedPlaces = response.data.upvotedPlaces))
        .catch(() => console.log("not logged in"));

      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/user/downvotedplaces", {
          withCredentials: true,
        })
        .then(
          (response) => (this.downvotedPlaces = response.data.downvotedPlaces)
        )
        .catch(() => console.log("not logged in"));
    }
  },
  methods: {
    upvote(place) {
      if (!this.upvotedPlaces.includes(place._id)) {
        place.upvotes++;
        this.upvotedPlaces.push(place._id);
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/upvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });

        if (this.downvotedPlaces.includes(place._id)) {
          this.downvotedPlaces.pop(place._id);
          place.downvotes--;
        }
      } else if (this.upvotedPlaces != null) {
        this.upvotedPlaces.pop(place._id);
        place.upvotes--;
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/upvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });
      }
    },
    downvote(place) {
      if (!this.downvotedPlaces.includes(place._id)) {
        place.downvotes++;
        this.downvotedPlaces.push(place._id);
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/downvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });

        if (this.upvotedPlaces.includes(place._id)) {
          this.upvotedPlaces.pop(place._id);
          place.upvotes--;
        }
      } else if (this.downvotedPlaces != null) {
        this.downvotedPlaces.pop(place._id);
        place.downvotes--;
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/downvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.homeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
  width: 90%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  //min-height: 100%;
  //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.featuredPlacesContainer,
.newPlacesContainer,
.nextEventsContainer {
  display: inline-block;
  flex-grow: 1;
  //width: 40%;
}

.containerTitle {
  text-align: left;
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 30px;
  color: #2b2c34;
  //margin-bottom: 10%;
}

#seeAllButton {
  text-decoration: none;
  color: #2b2c34;
  margin-top: 30%;
  font-weight: 300;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: inherit;
}

button.accordion-button:focus {
  box-shadow: inherit;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236246ea'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#accordion {
  //width: 90%;
  margin: auto;
  //margin-top: 0%;
  //height: 50px;
}

.accordion-button {
  height: 50px;
  padding: 0;
}

.accordion-body {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  margin-top: 2%;
}

.voteButton {
  margin: auto;
}

.accordionItem {
  margin: 0;
}

#warning {
  font-weight: 300;
  font-size: 12px;
}

#wrapper {
  width: 60%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  //min-height: 100%;
  //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pageTitle {
  text-align: left;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 40px;
  color: #2b2c34;
}

.pageSubTitle {
  text-align: left;
  font-weight: 300;
  font-size: 15px;
}

/* #upvoteButton {
  background-color: #6246ea;
  color: #ffffff;
}

#downvoteButton {
  background-color: #e45858;
  color: #ffffff;
} */

.placesContainer {
  border-radius: 1%;
  padding: 1em;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background-color: #ffffff;
  margin-top: 0%;
  padding: 0 2% 0 2%;
}

.container-fluid {
  margin: 0%;
  display: flex;
  flex-direction: row;
}

#name {
  font-weight: 700;
}

#name,
#category,
#zone,
#price,
#votes,
#upvoteButton,
#downvoteButton,
#votesVoted,
#votesDownvoted {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  //text-align: center;
}

#votesVoted {
  color: #6246ea;
}

#votesDownvoted {
  color: #e45858;
}

.show-on-desktop {
  display: inline;
}
.show-on-mobile {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .featuredPlacesContainer,
  .newPlacesContainer,
  .nextEventsContainer {
    margin: 0 auto 0 auto;
    max-width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  #wrapper {
    width: 90%;
  }

  #search {
    width: 80%;
    margin-top: 2%;
  }

  .pageTitle {
    text-align: center;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 25px;
    color: #2b2c34;
  }

  .pageSubTitle {
    text-align: center;
    font-weight: 300;
    font-size: 15px;
  }

  #zone {
    font-weight: 200;
  }

  .show-on-mobile {
    display: inline;
  }

  .show-on-desktop {
    display: none;
  }

  .homeContainer {
    margin-bottom: 20%;
  }

  .featuredPlacesContainer,
  .newPlacesContainer,
  .nextEventsContainer {
    margin-top: 10%;
  }
}
</style>