import { defineStore } from 'pinia'
import { useSessionStorage } from "@vueuse/core"
//import axios from "axios";


export const useUserStore = defineStore('user', {
  state: () => ({
    user: useSessionStorage('user', {username: null, role: null}),
    isAuthenticated: useSessionStorage('isAuthenticated', false),
    //user: useSessionStorage('user'),
    //user: null,
    //isLoggedIn: useSessionStorage('isLoggedIn'),
  }),

  actions: {
    // async fetchUser() {
    //   const res = await fetch("https://localhost:3000/user");

    //   const user = await res.json();
    //   this.user = user;
    // },
    async signup(email, username, password, code) {
      const res = await fetch(process.env.VUE_APP_API_ENDPOINT + "/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, username, password, code }),
      });
      //const user = await res.json()
      //this.user = user;

      return res.status;
    },
    async login(email, password) {

      const res = await fetch(process.env.VUE_APP_API_ENDPOINT + "/login", {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      // const res = await axios
      //   .post(
      //     "http://localhost:3000/api/login",
      //     {
      //       email: email,
      //       password: password,
      //     },
      //     {
      //       withCredentials: true
      //     },
      //     {
      //       headers: {
      //         //Authorization: `Basic ${this.userStore.user.token}`,
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   )
      //console.log(res.json());
      const user = await res.json();
      this.user = user;
      this.isAuthenticated = true;
      //console.log(user);
      return res.status;

    },
    async changePassword(password, newPassword) {
      const res = await fetch(process.env.VUE_APP_API_ENDPOINT + "/user/changepassword", {
        method: "PUT",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password, newPassword }),
      });

      return res.status;
    },
    async deleteAccount() {
      const res = await fetch(process.env.VUE_APP_API_ENDPOINT + "/user/delete", {
        method: "DELETE",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      });

      return res.status;
    }
  },

});