<template>
  <div id="wrapper">
    <h1 class="pageTitle">Sítios para descobrires</h1>
    <p class="pageSubTitle">Todos os sítios atualmente na plataforma</p>
    <div id="filters">
      <div class="dropdown">
        <select
          v-model="categorySelected"
          class="form-select"
          aria-label="Default select example"
        >
          <option value="Todos">Todos</option>
          <option value="Cafe">Café</option>
          <option value="Nightlife">Nightlife</option>
          <option value="Activity">Atividade</option>
          <option value="Sports">Desporto</option>
          <option value="Restaurant">Restaurante</option>
          <option value="Bar">Bar</option>
          <option value="Snacks">Snacks</option>
          <option value="Other">Outro</option>

        </select>
      </div>
      <div class="dropdown">
        <select
          v-model="zoneSelected"
          class="form-select"
          aria-label="Default select example"
        >
          <option value="Todos">Todos</option>
          <option value="Caldas da Rainha">Caldas da Rainha</option>
          <option value="Leiria">Leiria</option>
          <option value="Marinha Grande">Marinha Grande</option>
        </select>
      </div>

      <button type="button" class="btn" @click.prevent="filter()">
        <img src="../../public/check.svg" alt="Filter confirm" />
      </button>

      <form class="d-flex" role="search" id="search">
        <input
          class="form-control me-2"
          type="search"
          placeholder="search"
          aria-label="Search"
          v-model="textInput"
          v-on:input="search(textInput)"
        />
      </form>
    </div>
    <div class="placesContainer">
      <div class="spinner-border" role="status" v-if="!places">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div
        class="accordion accordion-flush"
        id="accordion"
        v-for="(place, index) in places"
        v-bind:key="place.id"
      >
        <div class="accordion-item">
          <h2 class="accordion-header" :id="'flush-heading' + index">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#flush-collapse' + index"
              aria-expanded="false"
              :aria-controls="'flush-collapse' + index"
            >
              <div class="container-fluid">
                <p class="accordionItem" id="name">{{ place.name }}</p>

                <p
                  class="accordionItem"
                  id="category"
                  v-if="place.category == 'Cafe'"
                >
                  Café
                </p>
                <p
                  class="accordionItem"
                  id="category"
                  v-else-if="place.category == 'Nightlife'"
                >
                  Nightlife
                </p>
                <p
                  class="accordionItem"
                  id="category"
                  v-else-if="place.category == 'Activity'"
                >
                  Atividade
                </p>
                <p
                  class="accordionItem"
                  id="category"
                  v-else-if="place.category == 'Sports'"
                >
                  Desporto
                </p>
                <p
                  class="accordionItem"
                  id="category"
                  v-else-if="place.category == 'Restaurant'"
                >
                  Restaurante
                </p>
                <p
                  class="accordionItem"
                  id="category"
                  v-else-if="place.category == 'Bar'"
                >
                  Bar
                </p>
                <p
                  class="accordionItem"
                  id="category"
                  v-else-if="place.category == 'Snacks'"
                >
                  Snacks
                </p>
                <p
                  class="accordionItem"
                  id="category"
                  v-else-if="place.category == 'Other'"
                >
                  Outro
                </p>
                <p class="accordionItem" id="category" v-else>Sem categoria</p>

                <p class="show-on-desktop accordionItem" id="zone">
                  {{ place.zone }}
                </p>

                <!-- <p class="accordionItem" id="price" v-if="place.price == 1">
                <b>€</b><span id="greyMoney">€€€€</span>
              </p>
              <p class="accordionItem" id="price" v-if="place.price == 2">
                <b>€€</b><span id="greyMoney">€€€</span>
              </p>
              <p class="accordionItem" id="price" v-if="place.price == 3">
                <b>€€€</b><span id="greyMoney">€€</span>
              </p>
              <p class="accordionItem" id="price" v-if="place.price == 4">
                <b>€€€€</b><span id="greyMoney">€</span>
              </p>
              <p class="accordionItem" id="price" v-if="place.price == 5">
                <b>€€€€€</b>
              </p> -->

                <p
                  class="show-on-desktop accordionItem"
                  id="votesVoted"
                  v-if="upvotedPlaces.includes(place._id)"
                >
                  {{ place.upvotes }} gostos
                </p>
                <p
                  class="show-on-desktop accordionItem"
                  id="votesDownvoted"
                  v-else-if="downvotedPlaces.includes(place._id)"
                >
                  {{ place.upvotes }} gostos
                </p>
                <p class="show-on-desktop accordionItem" id="votes" v-else>
                  {{ place.upvotes }} gostos
                </p>
              </div>
            </button>
          </h2>
          <div
            :id="'flush-collapse' + index"
            class="accordion-collapse collapse"
            :aria-labelledby="'flush-heading' + index"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              {{ place.description }}
              <p class="show-on-mobile accordionItem" id="zone">
                {{ place.zone }}
              </p>
              <div class="buttonsContainer">
                <button
                  class="btn voteButton"
                  id="upvoteButton"
                  type="button"
                  @click.prevent="upvote(place)"
                  v-if="
                    upvotedPlaces == null || !upvotedPlaces.includes(place._id)
                  "
                >
                  <img src="../../public/thumbs-up.svg" alt="Thumbs up icon" />
                </button>
                <button
                  class="btn voteButton"
                  id="upvoteButton"
                  type="button"
                  @click.prevent="upvote(place)"
                  v-if="
                    upvotedPlaces != null && upvotedPlaces.includes(place._id)
                  "
                >
                  <img
                    src="../../public/thumbs-up-clicked.svg"
                    alt="Thumbs up icon clicked"
                  />
                </button>
                <p
                  class="show-on-mobile accordionItem"
                  id="votesVoted"
                  v-if="upvotedPlaces.includes(place._id)"
                >
                  {{ place.upvotes }}
                </p>
                <p
                  class="show-on-mobile accordionItem"
                  id="votesDownvoted"
                  v-else-if="downvotedPlaces.includes(place._id)"
                >
                  {{ place.upvotes }}
                </p>
                <p class="show-on-mobile accordionItem" id="votes" v-else>
                  {{ place.upvotes }}
                </p>
                <button
                  class="btn voteButton"
                  id="downvoteButton"
                  type="button"
                  @click.prevent="downvote(place)"
                  v-if="
                    downvotedPlaces == null ||
                    !downvotedPlaces.includes(place._id)
                  "
                >
                  <img
                    src="../../public/thumbs-down.svg"
                    alt="Thumbs down icon"
                  />
                </button>
                <button
                  class="btn voteButton"
                  id="downvoteButton"
                  type="button"
                  @click.prevent="downvote(place)"
                  v-if="
                    downvotedPlaces != null &&
                    downvotedPlaces.includes(place._id)
                  "
                >
                  <img
                    src="../../public/thumbs-down-clicked.svg"
                    alt="Thumbs down icon clicked"
                  />
                </button>
                <p id="warning">Os votos negativos não são apresentados.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useUserStore } from "../store/user";

export default {
  name: "PlacesList",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      places: null,
      nonFilteredPlaces: null,
      wasUpvoted: false,
      wasDownvoted: false,
      categoryFilter: "",
      upvotedPlaces: [],
      downvotedPlaces: [],
      textInput: "",
      categorySelected: "Todos",
      zoneSelected: "Todos",
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/places", {
        withCredentials: true,
      })
      .then(
        (response) => (
          (this.places = response.data.data),
          (this.nonFilteredPlaces = response.data.data)
        )
      )
      .catch(() => {
        axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
          withCredentials: true,
        });
        localStorage.clear();
        sessionStorage.clear();
        //location.reload(); uncomment this after closed beta and remove next line
        this.$router.push({ name: "landingpage" });
      });

    if (this.userStore.user.username) {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/user/upvotedplaces", {
          withCredentials: true,
        })
        .then((response) => (this.upvotedPlaces = response.data.upvotedPlaces))
        .catch(() => console.log("not logged in"));

      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/user/downvotedplaces", {
          withCredentials: true,
        })
        .then(
          (response) => (this.downvotedPlaces = response.data.downvotedPlaces)
        )
        .catch(() => console.log("not logged in"));
    }
  },
  methods: {
    filter() {
      if (this.categorySelected == "Todos" && this.zoneSelected == "Todos") {
        this.places = this.nonFilteredPlaces;
        return;
      }
      this.places = this.nonFilteredPlaces;
      if (this.categorySelected != "Todos") {
        this.places = this.places.filter(
          (place) => place.category == this.categorySelected
        );
      }
      if (this.zoneSelected != "Todos") {
        this.places = this.places.filter(
          (place) => place.zone == this.zoneSelected
        );
      }
    },
    filterCategory(categorySelected) {
      //this.categoryFilter = category;
      this.categorySelected = categorySelected;
      if (categorySelected == "none" && this.zoneSelected == "none") {
        this.places = this.nonFilteredPlaces;
        return;
      }
      this.places = this.nonFilteredPlaces;
      this.places = this.places.filter(
        (place) => place.category == categorySelected
      );
    },
    filterZone(zoneSelected) {
      this.zoneSelected = zoneSelected;
      if (zoneSelected == "none" && this.categorySelected == "none") {
        this.places = this.nonFilteredPlaces;
        return;
      }
      this.places = this.nonFilteredPlaces;
      this.places = this.places.filter((place) => place.zone == zoneSelected);
    },
    search(textInput) {
      if (!textInput) {
        this.places = this.nonFilteredPlaces;
        return;
      }
      this.places = this.nonFilteredPlaces;
      this.places = this.places.filter((place) =>
        place.name.toLowerCase().includes(textInput)
      );
    },
    upvote(place) {
      if (!this.upvotedPlaces.includes(place._id)) {
        place.upvotes++;
        this.upvotedPlaces.push(place._id);
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/upvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });

        if (this.downvotedPlaces.includes(place._id)) {
          this.downvotedPlaces.pop(place._id);
          place.downvotes--;
        }
      } else if (this.upvotedPlaces != null) {
        this.upvotedPlaces.pop(place._id);
        place.upvotes--;
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/upvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });
      }
    },
    downvote(place) {
      if (!this.downvotedPlaces.includes(place._id)) {
        place.downvotes++;
        this.downvotedPlaces.push(place._id);
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/downvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });

        if (this.upvotedPlaces.includes(place._id)) {
          this.upvotedPlaces.pop(place._id);
          place.upvotes--;
        }
      } else if (this.downvotedPlaces != null) {
        this.downvotedPlaces.pop(place._id);
        place.downvotes--;
        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT + "/place/downvote/" + place._id,
            null,
            {
              withCredentials: true,
            }
          )
          .catch(() => {
            axios.get(process.env.VUE_APP_API_ENDPOINT + "/logout", {
              withCredentials: true,
            });
            localStorage.clear();
            sessionStorage.clear();
            //location.reload(); uncomment this after closed beta and remove next line
            this.$router.push({ name: "landingpage" });
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* this two selector remove accordion blue color when selected */
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: inherit;
}

button.accordion-button:focus, .form-select:focus {
  box-shadow: inherit;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236246ea'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#accordion {
  //width: 90%;
  margin: auto;
  //margin-top: 0%;
  //height: 50px;
}

.accordion-button {
  height: 50px;
  padding: 0;
}

.accordion-body {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  margin-top: 2%;
}

.voteButton {
  margin: auto;
}

.accordionItem {
  margin: 0;
}

#warning {
  font-weight: 300;
  font-size: 12px;
}

#wrapper {
  width: 60%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  //min-height: 100%;
  //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pageTitle {
  text-align: left;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 40px;
  color: #2b2c34;
}

.pageSubTitle {
  text-align: left;
  font-weight: 300;
  font-size: 15px;
}

/* #upvoteButton {
  background-color: #6246ea;
  color: #ffffff;
}

#downvoteButton {
  background-color: #e45858;
  color: #ffffff;
} */

.placesContainer {
  border-radius: 1%;
  padding: 1em;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background-color: #ffffff;
  margin-top: 3%;
  padding: 0 2% 0 2%;
}

.container-fluid {
  margin: 0%;
  display: flex;
  flex-direction: row;
}

#name {
  font-weight: 700;
}

#name,
#category,
#zone,
#price,
#votes,
#upvoteButton,
#downvoteButton,
#votesVoted,
#votesDownvoted {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  //text-align: center;
}

#votesVoted {
  color: #6246ea;
}

#votesDownvoted {
  color: #e45858;
}

#filters button {
  margin-right: 1%;
}

#filters {
  margin-top: 4%;
  margin-bottom: 1%;
  display: flex;
  //flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.form-select {
  background-color: #6246ea;
  color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fffffe' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.dropdown {
  margin-right: 1%;
}

#search {
  width: 50%;
  //margin: auto;
  margin-top: 0%;
  //margin-left: 1%;
}

#greyMoney {
  color: rgb(194, 194, 194);
}

.show-on-desktop {
  display: inline;
}
.show-on-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  #wrapper {
    width: 90%;
    margin-bottom: 20%;
  }

  #search {
    width: 80%;
    margin-top: 2%;
  }

  .pageTitle {
    text-align: center;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 25px;
    color: #2b2c34;
  }

  .pageSubTitle {
    text-align: center;
    font-weight: 300;
    font-size: 15px;
  }

  #zone {
    font-weight: 200;
  }

  .show-on-mobile {
    display: inline;
  }

  .show-on-desktop {
    display: none;
  }
}
</style>