<template>
  <footer>
    <div class="footerContainer">
      <div class="brandContainer">
        <a
          @mouseover="fakeTitle"
          @mouseleave="realTitle"
          class=""
          id="title"
          href="/"
        >
          {{ title }}</a
        >
      </div>
      <div class="linkContainer">
        <a class="footerText" href="/about">Sobre a plataforma</a>
        <a class="footerText" href="#">Política de privacidade</a>
        <a class="footerText" href="#">Termos e condições</a>
      </div>

      <div class="contactsContainer">
        <a class="footerText" href="mailto:support@wherever.pt">support@wherever.pt</a>
      </div>
    </div>
    <!-- <div class="bottomFooterContainer">
      <p class="bottomFooterText">Todos os direitos reservados.</p>
    </div> -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
  setup() {},
  data() {
    return {
      title: "wherever.pt",
    };
  },
  // props: {
  // 	msg: String,
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footerContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin: auto;
}

.footerContainer > div {
  flex-grow: 1;
  width: 33%;
}

.bottomFooterContainer {
  border-top: 1px solid #d1d1e9;
  width: 60%;
  //min-height: 10px;
  margin: auto;
  margin-bottom: 0;
}

.bottomFooterText {
  font-weight: 200;
  font-size: 13px;
  margin-bottom: 0;
}

.brandContainer {
  display: flex;
  flex-direction: column;
}

.linkContainer,
.contactsContainer {
  text-align: left;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.footerText {
  text-decoration: none;
  color: #2b2c34;
  font-weight: 400;
  font-size: 15px;
}

#title {
  margin: auto;
  text-decoration: none;
  //margin-bottom: 10;
  //width: 200%;
  /* background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  //font-family: 'Roboto Mono', monospace;
  font-weight: 900;
  color: #6246ea;
  font-size: 25px;
  letter-spacing: 3px;
}

footer {
  min-height: 120px;
  //max-height: 120px;
  //background: white;
  //margin-bottom: 0%;
  //position: sticky;
  //width: 100%;
  //margin-top: 5%;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //margin-top: auto;

  //display: flex;
  //flex-direction: column;

  //widows: 100%;
  border-top: 2px solid #6246ea;
}

@media only screen and (max-width: 768px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    //flex-wrap: wrap;
    //height: 100%;
    width: 90%;
  }

  footer {
    min-height: 200px;
    //display: inline;
  }

  .footerContainer > div {
    flex-grow: 1;
    width: 100%;
  }

  .linkContainer {
  display: flex;
}

  /* .brandContainer {
  margin: 0;
  padding: 0;
  width: 200%;
}



.footerText {
  width: 300%;
} */

  .brandContainer,
  .linkContainer,
  .contactsContainer {
    align-items: center;
  }
}
</style>
