<template>

    <FeaturedPlaces />


  <router-view />
</template>

<script>
import FeaturedPlaces from '../components/FeaturedPlaces.vue';


export default {
  name: "Home",
  components: {
    FeaturedPlaces
    },
};
</script>

<style>



</style>