import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import App from './App.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

library.add(faPlus, faChevronDown, faThumbsUp)

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {pt} from '../src/locales/pt.js'
import {en} from '../src/locales/en.js'

const pinia = createPinia()

const languages = {
    pt,
    en,
};

const i18n = createI18n({
    locale: 'pt',
    fallbackLocale: 'pt',
    messages: languages,
});

createApp(App).use(router).use(pinia).use(i18n).component('font-awesome-icon', FontAwesomeIcon).mount('#app')

import "bootstrap/dist/js/bootstrap.js"
import router from './router'
